export const resources_loading_screen = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      loading_screen: {
        start: {
          text_0: 'identify',
          text_1: 'understand',
          text_2: 'explain',
          text_3: 'control',
          text_4: 'quantify',
          text_5: 'visualize',
          text_6: 'synthetize',
          text_7: 'justify',
        },
        end: {
          text_0: 'informations',
          text_1: 'financials',
          text_2: 'monetaries',
          text_3: 'materials',
          text_4: 'energy',
          text_5: 'wastes',
          text_6: 'transportation',
          text_7: 'valorisation',
          text_8: 'production',
        }
      }
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      loading_screen: {
        start: {
          text_0: 'Identifiez',
          text_1: 'Comprenez',
          text_2: 'Expliquez',
          text_3: 'Controlez',
          text_4: 'Quantifiez',
          text_5: 'Visualisez',
          text_6: 'Synthetisez',
          text_7: 'Justifiez',
        },
        end: {
          text_0: 'd\'informations',
          text_1: 'financiers',
          text_2: 'monétaires',
          text_3: 'de matière',
          text_4: 'd\'énergie',
          text_5: 'de déchets',
          text_6: 'de transport',
          text_7: 'à valoriser',
          text_8: 'de production',
        }
      }
    }
  }
}